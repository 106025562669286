.customer-page-container {
  .total-customers-box {
    padding: 18px;
    box-shadow: 0px 1px 2px 0px #1018280d;
    border: 1px solid #eaecf0;
    border-radius: 12px;
    width: 240px;

    .number {
      color: #101828;
    }

    .box-title {
      margin-bottom: 8px;
      color: #475467;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

    .percentage-box {
      padding: 6px 10px;
      border: 1px solid #abefc6;
      background-color: #ecfdf3;
      border-radius: 16px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: #067647;

      svg {
        font-size: 14px;
        margin-right: 6px;
        color: #17b26a;
      }
    }
  }

  .table-header-container {
    margin: 20px 0px;

    .search-bar {
      margin: 0px;

      svg {
        top: 11px;
      }

      .search-input {
        .MuiInputBase-root {
          height: 40px;
        }
      }
    }
  }

  .common-table {
    >div {
      border: none;
    }

    div {
      &:focus {
        outline: none !important;
      }

      &:focus-within {
        outline: none !important;
      }
    }

    .MuiDataGrid-columnHeaderTitleContainer {
      display: flex;
      justify-content: left;
    }

    .MuiDataGrid-columnHeaders {
      text-align: left;

      [role="row"] {
        background-color: #f9fafb !important;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .MuiDataGrid-columnHeader {
        border: none;
        color: #475467;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;

        .MuiCheckbox-root {
          color: #d0d5dd;
          padding-left: 0px;
        }

        .Mui-checked {
          color: #384cff;
        }

        &.MuiDataGrid-columnHeader--moving {
          background: transparent;
        }
      }
    }

    .MuiDataGrid-row {
      .MuiCheckbox-root {
        color: #d0d5dd;
      }

      .Mui-checked {
        color: #384cff;
      }

      .MuiDataGrid-cell {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        font-weight: 400;
        color: #101828;
        line-height: normal;

        .customer-box {
          img {
            margin-right: 12px;
          }

          .customer-name {
            font-size: 14px;
            line-height: 20px;
            color: #101828;
            font-weight: 500;
          }
        }

        .brand-number {
          color: #101828;
          font-weight: 500;
          line-height: 20px;
          font-size: 14px;
        }

        .about-details {
          .title {
            font-size: 14px;
            font-weight: 400;
            color: #101828;
            line-height: normal;
          }

          .decription {
            font-size: 12px;
            font-weight: 400;
            color: #101828;
            line-height: normal;
          }
        }
      }
    }
  }
}